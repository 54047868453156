@import "../styles/variables.scss";

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .activity-button {
        position: fixed;
        z-index: 1;
        .button {
            background: $light-blue;
        }
    }
}